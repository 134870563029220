import React from 'react'
import Auth from '../components/Auth'
import { ActivityIndicator } from '../components/generic'
import Layout from '../components/Layout'
import SEO from '../components/seo'

function SignInPage() {
  if (typeof window === 'undefined') {
    return <ActivityIndicator />
  }

  return <Layout noHeader noMoku noFooter>
    <SEO title="MyMeerkat Sign In" />
    <Auth />
  </Layout>
}

export default SignInPage